<template>
  <div><h1 class="heading">О программе лояльности «БЛАГАДАРЮ»</h1></div>
  

  <text-block>

    <p>
      Программа лояльности «БЛАГАДАРЮ» предназначена для розничных продавцов следующих брендов:
    </p>

    <p>
      <ul>
        <li>Ekko</li>
        <li>Ekko by Maxonor,</li>
        <li>Vigro</li>
        <li>Maxonor PURE LIFE</li>
        <li>Maxonor PURE LIFE - SALON</li>
      </ul>
    </p>

    <p>
      В программе участвует номенклатура следующих категорий:
    </p>

    <p>
      <ul>
        <li>Смесители всех указанных брендов</li>
        <li>Душевые системы всех указанных брендов</li>
        <li>Vigro</li>
        <li>Мойки каменные Vigro</li>
      </ul>
    </p>

    <p>При продаже изделий со специальными наклейками на упаковке, продавцы накапливают баллы, которые затем обменивают на сертификаты розничных сетевых магазинов, лучших интернет-магазинов и онлайн сервисов.</p>

    <p>Для участия в программе необходимо пройти регистрацию на сайте Благадарю.рф</p>

    <p>Для того чтобы накапливать баллы необходимо:</p>

    <p>
      <ul>
        <li>при продаже отсканировать специальный QR код программы БЛАГАДАРЮ, размещенный на упаковке;</li>
      </ul>
    </p>

    <p>либо</p>

    <p>
      <ul>
        <li>ввести уникальный цифровой код, размещенный под QR кодом на странице «Заработать баллы» сайта Благадарю.рф</li>
      </ul>
    </p>

    <p>Стоимость 1 балла – 1 рубль РФ.</p>

    <p>Подробную информацию о количестве баллов за каждый вид продукции и условия получения статусов можно получить на странице правила начисления после прохождения регистрации.</p>

    <p>После прохождения регистрации сумму балов за каждое изделие можно узнать, отсканировав QR код на упаковке продукции. В периоды проведения акций баллы могут быть увеличены.</p>

    <p>После сканирования QR кода или ввода цифрового кода проводится автоматическая проверка того, что данное изделие действительно куплено магазином (проверка оплаты накладной) после чего баллы становятся доступными к списанию.</p>

    <p>Ежемесячно начисленные бонусные баллы сгорают при невыполнении следующего условия: Объем продаж за предыдущие 3 (три) календарных месяца по товару, подлежащему QR-кодированию должен составлять не менее 30 000 (тридцать тысяч) рублей</p>

    <p>Продавец может обменять их на призы в личном кабинете сайта Благадарю.рф</p>
  </text-block>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
h1.heading {
  margin-bottom: 50px;
}
</style>
